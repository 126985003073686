import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { graphql } from 'gatsby'

import Layout from '@/layouts/Default'
import { Helmet } from 'react-helmet'

import { isMobileState } from '@/recoil/layout'

import Hero from '@/components/Hero'
import HeroCard from '@/components/Hero/HeroCard'
import ContactForm from '@/components/Forms/ContactForm'

import StoreLocator from '@/components/StoreLocator'
import Community from '@/components/Community'
import CircularLabelButton from '@/components/CircularLabelButton'
import Button from '@/components/Button'

const ContactPage = ({ data, pageContext: { pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const socialData = data.drupal.CommunityQuery?.fieldJournalJoinOurCommunity?.entity
  const heroData = data.drupal.HeroQuery

  const getVineyardItems = _ => {
    const VineyardItem = []
    for (let i = 0; i < heroData?.fieldContactVineyardList?.entity.fieldContactListVineyardItem.length; i++) {
      const item = heroData?.fieldContactVineyardList?.entity.fieldContactListVineyardItem[i].entity
      VineyardItem.push({
        icon: 'icon-house-contact',
        label: item.fieldContactVineyardName,
        content: `${item.fieldContactVineyardAddress.addressLine1} ${item.fieldContactVineyardAddress.addressLine2}`,
        lat: item.fieldContactVineyardGmap.lat,
        lon: item.fieldContactVineyardGmap.lon,
        number: item.fieldContactVineyardPhone
      })
    }

    return VineyardItem
  }

  const vineyardList = getVineyardItems()

  const getSocialList = _ => {
    const socials = []
    for (let i = 0; i < socialData.fieldSocialPostsList.entity.fieldSocialPostItem.length; i++) {
      const item = socialData.fieldSocialPostsList.entity.fieldSocialPostItem[i].entity

      socials.push({
        url: item.fieldSocialPostItemLink.uri,
        coverImg: item.fieldSocialPostItemCoverImg.url,
        coverAlt: item.fieldSocialPostItemCoverImg.alt,
        logo: item.fieldSocialPostLogo.url,
        logoAlt: item.fieldSocialPostLogo.alt,
        postUsername: item.fieldSocialPostItemUsername,
        postSocialAccount: item.fieldSocialPostUserAccount.title,
        postSocialAccountUrl: item.fieldSocialPostUserAccount.url.path
      })
    }
    return socials
  }

  const socialsList = getSocialList()

  return (
    <Layout path={ pagePath } pageType='Contact' pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>Contact us | Please Get In Touch | Cloudy Bay</title>
        <meta name='description' content='To receive more details about our Cloudy Bay wines or experiences in Marlborough or Central Otago, please get in touch with us.' />
      </Helmet>
      <Hero
        id="textcercle1"
        subtitle={ heroData?.fieldContactSubtitle }
        title={ heroData?.fieldContactTitle }
        text={ heroData?.fieldContactIntroduction }
        image={ isMobile ? heroData?.fieldContactBackgroundMobile.url : heroData?.fieldContactBackgroundDesktop.url }
        actionLabel={ heroData?.fieldContactCtaLabel }
      >
        <HeroCard
          title={ heroData?.fieldContactVineyardsTitle }
          text=''
          items={ vineyardList }
          display='column'
          actions={ [
            // <Button key={ 1 } to='#contact'>Contact us</Button>
          ] }
          block='contact-us'
        />
      </Hero>
      <ContactForm />
      <Community
        title={ socialData.fieldSocialPostsList.entity.fieldSocialListTitle }
        subtitle={ socialData.fieldSocialPostsList.entity.fieldSocialListSubtitle }
        text={ socialData.fieldSocialPostsList.entity.fieldSocialListDescription }
        action={
          <CircularLabelButton
            id="textcercle2"
            label={ socialData.fieldSocialPostsList.entity.fieldSocialListCta?.title }
            to={ socialData.fieldSocialPostsList.entity.fieldSocialListCta?.url.path }
            target='_blank'
            nbLabels={ isMobile ? 3 : 5 }
          />
        }
        items={ socialsList }
      />
      <StoreLocator pageType='contact'/>
    </Layout>
  )
}

export const query = graphql`
query ContactDataQuery{
  drupal {
    CommunityQuery : nodeById(id: "272") {
      ... on Drupal_NodeJournal {
        fieldJournalJoinOurCommunity {
          entity {
            ... on Drupal_NodeStandardSocialPosts {
              fieldSocialPostsList {
                entity {
                  ... on Drupal_ParagraphSocialPostItemList {
                    fieldSocialListCta {
                      uri
                      title
                      options
                      url{
                        path
                      }
                    }
                    fieldSocialPostItem {
                      entity {
                        ... on Drupal_ParagraphSocialPostItem {
                          fieldSocialPostLogo {
                            height
                            url
                            width
                            targetId
                            alt
                            title
                          }
                          fieldSocialPostItemLink {
                            uri
                            title
                            options
                          }
                          fieldSocialPostItemCoverImg {
                            url
                            alt
                          }
                          fieldSocialPostItemUsername
                          fieldSocialPostUserAccount {
                            title
                            url {
                              path
                            }
                          }
                        }
                      }
                    }
                    fieldSocialListTitle
                    fieldSocialListSubtitle
                    fieldSocialListDescription
                  }
                }
              }
              fieldSocialPostsMarkets {
                targetId
              }
            }
          }
        }
      }
    }
    HeroQuery: nodeById(id: "12") {
      ... on Drupal_NodeContactPage {
        nid
        fieldContactTitle
        fieldContactVineyardsTitle
        fieldContactIntroduction
        fieldContactCtaLabel
        fieldContactSubtitle
        fieldContactBackgroundMobile {
          url
          title
          alt
        }
        fieldContactBackgroundDesktop {
          url
          title
          alt
        }
        fieldContactVineyardList {
          entity {
            id
            ... on Drupal_ParagraphContactVineyardsList {
              id
              fieldContactListVineyardItem {
                entity {
                  id
                  ... on Drupal_ParagraphVineyardItem {
                    id
                    fieldContactVineyardPhone
                    fieldContactVineyardName
                    fieldContactVineyardGmap {
                      lat
                      lon
                    }
                    fieldContactVineyardAddress {
                      addressLine1
                      addressLine2
                      postalCode
                      countryCode
                      locality
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export default ContactPage
