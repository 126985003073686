import * as React from 'react'
import axios from 'axios'
import { graphql, useStaticQuery, Link } from 'gatsby'
import EmailField from '@/components/Forms/Inputs/Email'
import TextField from '@/components/Forms/Inputs/Text'
import TextAreaField from '@/components/Forms/Inputs/TextArea'
import SelectField from '@/components/Forms/Inputs/Select'
import CheckboxField from '@/components/Forms/Inputs/Checkbox'
import DateFormat from '@/components/Forms/Inputs/DateFormat'

import { useRecoilState, useRecoilValue } from 'recoil'
import { countryState } from '@/recoil/ageGate'
import { customerManager } from '@/recoil/customer'

import countriesData from '@/config/legal_age.json'
import statesData from '@/config/states_hash.json'
import statesDataCA from '@/config/states_hash_ca.json'

import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'

import { marketCodeManager } from '@/recoil/marketCode'

import Button from '@/components/Button'

import { checkFormData } from '@/utils/forms'
import { getAge } from '@/utils/date'
import { CONTACT_FORM } from '@/utils/gtmEvents'
import { useRef, useState, useEffect } from 'react'
import { MDYCountries } from './config'

import {
  Container,
  GridContainer,
  Head,
  Title,
  Subtitle,
  InputWrapper,
  FormContainer,
  FormGroup,
  FormGroupLabel,
  GridInputWrapper,
  Checkboxes,
  Consent,
  ErrorLabel,
  LegalsMention,
  Mandatory,
  SuccessMessage
} from './style'

const TWO_OPTIN_COUNTRIES = ['Japan', 'Poland', 'Germany']
const CENTRAL_OTAGO = 'central-otago'
const MARLBOROUGH = 'marlborough'

// ?subject=central-otago => central-otago = (Visit - Central Otago / The Shed) lowercase & kebab case => include  => Visit - Central Otago / The Shed
// ?subject=marlborough => marlborough = (Visit - Marlborough) lowercase & kebab case => include => Visit - Marlborough

const ContactForm = () => {
  const data = useStaticQuery(graphql`
    query ContactFormQuery {
      drupal {
        webformById(webform_id: "contact") {
          title
          description
          elements {
            ... on Drupal_WebformElement {
              id
              type
            }
            ... on Drupal_WebformElementActions {
              submitLabel
              title
            }
            ... on Drupal_WebformElementTextBase {
              title
              defaultValue
              required {
                message
              }
              size
              minLength
              maxLength
              pattern {
                message
                rule
              }
              placeholder
              multiple {
                limit
                message
              }
            }
            ... on Drupal_WebformElementMarkup {
              markup
            }
            ... on Drupal_WebformElementTextarea {
              rows
            }
            ... on Drupal_WebformElementHidden {
              defaultValue
            }
            ... on Drupal_WebformElementDate {
              dateMin
              dateMax
              step
              defaultValue
              title
              required {
                message
              }
              multiple {
                limit
                message
              }
            }
            ... on Drupal_WebformElementOptionsBase {
              title
              defaultValue
              options {
                title
                value
              }
              required {
                message
              }
              multiple {
                limit
                message
              }
            }
            ... on Drupal_WebformElementSelect {
              emptyOption {
                title
                value
              }
            }
            ... on Drupal_WebformElementManagedFile {
              title
              fileExtensions
              required {
                message
              }
              multiple {
                limit
                message
              }
            }
            ... on Drupal_WebformElementTermSelect {
              title
              termOptions(depth: 1) {
                entityId
                entityLabel
              }
            }
            ... on Drupal_WebformElementComposite {
              elements {
                id
                type
              }
            }
            ... on Drupal_WebformElementNumber {
              required {
                message
              }
              min
              max
              size
              step
            }
          }
        }
      }
    }
  `)

  const customer = useRecoilValue(customerManager)
  const marketCode = useRecoilValue(marketCodeManager)

  const formConfigData = data?.drupal?.webformById?.elements
  const objectFieldConfig = formConfigData.find((field) => field.id === 'subject')
  const emailFieldConfig = formConfigData.find((field) => field.id === 'email')
  const countryFieldConfig = formConfigData.find((field) => field.id === 'locationofresidence')
  const lastnameFieldConfig = formConfigData.find((field) => field.id === 'name')
  const firstnameFieldConfig = formConfigData.find((field) => field.id === 'first_name')
  const birthdateFieldConfig = formConfigData.find((field) => field.id === 'birth_date')
  const messageFieldConfig = formConfigData.find((field) => field.id === 'message')
  const titleFieldConfig = formConfigData.find((field) => field.id === 'title')

  const requiredFieldsText = formConfigData.find((field) => field.id === 'required_fields_warning')
  const consentWithdrawalText = formConfigData.find((field) => field.id === 'consrnt_withdrawal')
  const cloudyBayConsentText = formConfigData.find((field) => field.id === 'cloudy_bay_consent_text')
  const personalDataInformationText = formConfigData.find((field) => field.id === 'personal_data_information')
  const mhConsentText = formConfigData.find((field) => field.id === 'mh_consent_text')

  const [error, setError] = useState(false)
  const [countryName, setCountryName] = useRecoilState(countryState)
  const [stateName, setStateName] = useState(null)
  const placeholderRef = useRef(null)
  const objectRef = useRef(null)
  const emailRef = useRef(null)
  const firstnameRef = useRef(null)
  const lastnameRef = useRef(null)
  const countryRef = useRef(null)
  const stateRef = useRef(null)
  const birthDayRef = useRef(null)
  const titleRef = useRef(null)
  const birthMonthRef = useRef(null)
  const birthYearRef = useRef(null)
  const messageRef = useRef(null)
  const newsletterRef = useRef(null)
  const dataSharingRef = useRef(null)
  const analysisRef = useRef(null)
  const [isUsa, setIsUsa] = useState(false)
  const [isCanada, setIsCanada] = useState(false)
  const [stateList, setStateList] = useState({})
  const [isSend, setIsSend] = useState(false)
  const [displayState, setDisplayState] = useState(false)

  const validateDate = (value, country) => {
    let valid = false
    const minAge = countriesData.countries.find((c) => c.label === country).legal_age

    if (minAge >= 0 && value.length === 10) {
      const age = getAge(value)
      valid = (age >= minAge)
    }

    return valid
  }

  useEffect(() => {
    setStateList(getStateData(countryName === 'USA', countryName === 'Canada'))
    setIsUsa(countryName === 'USA')
    setIsCanada(countryName === 'Canada')
  }, [countryName])

  const getStateData = (usa, canada) => {
    if (usa) { return statesData }

    if (canada) { return statesDataCA }

    return {}
  }

  const handleSubmit = async (e) => {
    setError(false)
    e.preventDefault()

    const inputs = [
      objectRef,
      emailRef,
      firstnameRef,
      lastnameRef,
      countryRef,
      stateRef,
      birthDayRef,
      titleRef,
      birthMonthRef,
      birthYearRef,
      messageRef,
      newsletterRef,
      analysisRef
    ]

    if (TWO_OPTIN_COUNTRIES.includes(countryName)) {
      inputs.push(dataSharingRef)
    }

    const formData = checkFormData(inputs)

    if (!formData.errors.length) {
      const {
        data: {
          day,
          month,
          year,
          firstname,
          lastname,
          email,
          message,
          title,
          subject,
          country,
          data_consent
        }
      } = formData

      // check birthdate inputs format
      const dayFormat = day.length === 2 && parseInt(day) >= 1 && parseInt(day) <= 31
      const monthFormat = month.length === 2 && parseInt(month) >= 1 && parseInt(month) <= 12
      const yearFormat = year.length === 4 && parseInt(year) >= 1920 && parseInt(year) <= new Date().getFullYear()
      if (!dayFormat || !monthFormat || !yearFormat) {
        if (!dayFormat) birthDayRef.current.triggerError(true) && setError(true)
        if (!monthFormat) birthMonthRef.current.triggerError(true) && setError(true)
        if (!yearFormat) birthYearRef.current.triggerError(true) && setError(true)
        return
      }

      // check if birthdate is valid
      const birthDate = `${year}-${month}-${day}`
      const checkBirthDate = `${day}/${month}/${year}`
      const isDateValid = validateDate(checkBirthDate, country)

      const countryCode = countriesData.countries.find((c) => c.label === formData.data.country)?.countryCode
      const locationOfResidenceIso3 = countriesData.countries.find(item => item.countryCode === countryCode)?.countryCodeISO3

      if (!isDateValid) {
        birthDayRef.current.triggerError(true)
        birthMonthRef.current.triggerError(true)
        birthYearRef.current.triggerError(true)
        setError('age')
      } else {
        const data = {
          location_of_residence_iso3: locationOfResidenceIso3,
          webform_id: 'contact',
          touchpoint: 'contact',
          legal_age: true,
          email: email,
          first_name: firstname,
          name: lastname,
          message: message,
          birth_date: birthDate,
          title: titleFieldConfig.options.find((o) => o.title === title)?.value,
          subject: objectFieldConfig.options.find((o) => o.title === subject)?.value,
          locationofresidence: countryCode,
          state: (isUsa || isCanada) ? Object.entries(stateList).find(([key, value]) => value === stateName)[0] : '',
          mh_entities_consent: isUsa ? false : data_consent,
          cloudy_bay_data_consent: data_consent,
          market: countryCode
        }

        const response = await axios.post(process.env.GATSBY_DRUPAL_FORM_SUBMISSION_ENDPOINT, data)

        setIsSend(true)
      }
    }
  }

  if (isSend) {
    window.dataLayer && window.dataLayer.push({
      event: CONTACT_FORM
    })
  }

  const onCountryChange = (value) => {
    setCountryName(value)
    setError(false)
    setDisplayState(value === 'USA' || value === 'Canada')
  }

  useEffect(() => {
    if (customer) {
      const userCountry = countriesData.countries.find((country) => country.countryCode === customer.countryCode)?.label

      if (userCountry) {
        onCountryChange(userCountry)
      }
    }
  }, [customer])

  const onStateChange = (value) => {
    setStateName(value)
    setError(false)
  }

  const getFieldLabel = (field) => `${field?.title}${field?.required ? '*' : ''}`

  const toKebabCase = (str = '') => str ? str.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '-').toLowerCase() : ''

  const formatSubject = (subject, options) => {
    const formattedSubject = toKebabCase(subject)

    const option = options.find((option) => {
      return toKebabCase(option.title).includes(formattedSubject)
    })

    return option ? option.value : ''
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    placeholderRef.current = formatSubject(searchParams.get('subject'), objectFieldConfig?.options)
  }, [])

  return (
    <Container id='contact'>
      <GridContainer>
        <FormContainer onSubmit={ handleSubmit } noValidate>
          <Subtitle>Contact Form</Subtitle>
          <Title>Questions or suggestions?</Title>
          <FormGroup>
            <InputWrapper>
              <SelectField
                ref={ objectRef }
                label={ getFieldLabel(objectFieldConfig) }
                placeholder={ objectFieldConfig?.emptyOption.title }
                name='subject'
                list={ objectFieldConfig?.options.map((object) => object.title) }
                defaultValue={ placeholderRef.current }
                isRequired
                onChange={ () => setError(false) }
                withDropDownIcon
                withQuickSearch
              />
            </InputWrapper>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>About You</FormGroupLabel>
            <GridInputWrapper cols={ displayState ? '3' : '2' }>
              <EmailField
                ref={ emailRef }
                label={ getFieldLabel(emailFieldConfig) }
                placeholder={ emailFieldConfig?.placeholder }
                name='email'
                isRequired={ !!emailFieldConfig?.required }
                onChange={ () => setError(false) }
              />
              <SelectField
                ref={ countryRef }
                label={ getFieldLabel(countryFieldConfig) }
                placeholder={ countryFieldConfig?.emptyOption?.title }
                name='country'
                isRequired={ !!countryFieldConfig?.required }
                separation
                list={
                  countriesData.countries.map((country) => country.label)
                }
                onChange={ onCountryChange }
                withDropDownIcon
                withQuickSearch
                defaultValue={ countriesData.countries.find((country) => country?.countryCode === customer?.countryCode)?.label }
              />
              {(displayState && (isUsa || isCanada)) && (
                <SelectField
                  ref={ stateRef }
                  label={ isCanada ? 'Select your Province' : 'State of residence*' }
                  placeholder={ isCanada ? 'Select your Province' : 'Select your State' }
                  name='state'
                  list={ Object.values(stateList) }
                  isRequired
                  onChange={ onStateChange }
                  withDropDownIcon
                />
              )}
            </GridInputWrapper>
          </FormGroup>
          <FormGroup>
            <GridInputWrapper cols='3'>
              <SelectField
                ref={ titleRef }
                label={ getFieldLabel(titleFieldConfig) }
                placeholder={ titleFieldConfig?.emptyOption?.title }
                name='title'
                list={ titleFieldConfig?.options?.map((option) => option.title) }
                isRequired={ !!titleFieldConfig?.required }
                onChange={ () => setError(false) }
                withDropDownIcon
                withQuickSearch
              />
              <TextField
                ref={ firstnameRef }
                label={ getFieldLabel(firstnameFieldConfig) }
                name='firstname'
                placeholder={ firstnameFieldConfig?.placeholder }
                isRequired={ !!firstnameFieldConfig?.required }
              />
              <TextField
                ref={ lastnameRef }
                label={ getFieldLabel(lastnameFieldConfig) }
                name='lastname'
                placeholder={ lastnameFieldConfig?.placeholder }
                isRequired={ !!lastnameFieldConfig?.required }
              />
            </GridInputWrapper>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Birth Date</FormGroupLabel>
            <GridInputWrapper cols='3'>
              <DateFormat
                dateFormat={ MDYCountries.indexOf(countryName) !== -1 || displayState ? 'MM/DD/YYYY' : 'DD/MM/YYYY' }
                dayComponent={
                  <TextField
                    ref={ birthDayRef }
                    label='Day*'
                    placeholder='DD'
                    name='day'
                    type='number'
                    min='1'
                    max='31'
                    maxLength={ 2 }
                    isRequired={ !!birthdateFieldConfig?.required }
                  />
                }
                monthComponent={
                  <TextField
                    ref={ birthMonthRef }
                    label='Month*'
                    placeholder='MM'
                    name='month'
                    type='number'
                    min='1'
                    max='12'
                    maxLength={ 2 }
                    isRequired={ !!birthdateFieldConfig?.required }
                  />
                }
                yearComponent={
                  <TextField
                    ref={ birthYearRef }
                    label='Year*'
                    placeholder='YYYY'
                    name='year'
                    type='number'
                    min='1900'
                    max={ new Date().getFullYear() }
                    maxLength={ 4 }
                    isRequired={ !!birthdateFieldConfig?.required }
                  />
                }
              />
            </GridInputWrapper>
          </FormGroup>
          {error === 'age' && (
            <ErrorLabel>
              Unfortunately you cannot validate this form as you are not of legal drinking and purchasing age
            </ErrorLabel>
          )}
          <FormGroup>
            <InputWrapper>
              <TextAreaField
                ref={ messageRef }
                label={ getFieldLabel(messageFieldConfig) }
                placeholder={ messageFieldConfig?.placeholder }
                name='message'
                isRequired={ !!messageFieldConfig?.required }
              />
            </InputWrapper>
          </FormGroup>

          <FormGroup>
            <Consent>
              Fields with an * are mandatory – not providing this information will not permit the processing of your request.
            </Consent>
            <Checkboxes>
              {
                isUsa && (
                  <>
                    <Consent>
                      <p>
                        Information collected will be used as described here, in our <a href='/en-us/privacy-cookies-notice/#notice-collection'>Notice at Collection</a> and our <a href='/en-us/privacy-cookies-notice'>Privacy Policy</a>.
                      </p>
                    </Consent>
                    <CheckboxField
                      ref={ newsletterRef }
                      name='data_consent'
                    >
                      <Consent>
                        <p>
                          By checking this box and clicking “submit”, I agree to receive email and other marketing communications from Cloudy Bay and the <a href='https://www.lvmh.com/houses/wines-spirits/' target='_blank' rel='noreferrer'>Moet Hennessy Entities</a>. I understand that information collected will be used as described here, the <a href='/en-us/privacy-cookies-notice/#notice-collection'>Notice at Collection</a> and our <a href='/en-us/privacy-cookies-notice/'>Privacy Policy</a>.
                        </p>
                      </Consent>
                    </CheckboxField>
                  </>
                )
              }
              {
                (['Germany', 'Poland', 'Japan'].includes(countryName)) && (
                  <>
                    <Consent>
                      <p>
                        By clicking on “submit”, I confirm I have read the <a href='/en-ww/privacy-cookies-notice/'>Information note on the processing of personal data and cookies</a>.
                      </p>
                    </Consent>
                    <Consent>
                      <p>
                        Your personal data are is processed by Cloudy Bay to ensure the efficient processing of your request (and/or other purpose). For more information on the processing of your data and on your rights, you can visit our <a href='/en-ww/privacy-cookies-notice/'>Information on the processing of personal data and cookies</a>.
                      </p>
                    </Consent>
                    <CheckboxField
                      ref={ newsletterRef }
                      name='data_consent'
                    >
                      <Consent>
                        <p>
                          Yes! I consent to be the first to receive by email personalized communications and be eligible to receive invitations to exclusive VIP events. This may involve utilizing information from various sources, to create personalized experiences and offer relevant content. In order to better serve my needs, I understand that <a href='/en-ww/privacy-cookies-notice/'>my data</a> is processed, analyzed and shared with our parent company, <a href='https://www.lvmh.com/houses/' target='_blank' rel='noreferrer'>LVMH</a>, which includes Cloudy Bay plus other prestigious champagne, spirits, fashion, jewelry and hospitality brands.
                          My interaction with communication may be measured by automatic means.
                        </p>
                      </Consent>
                    </CheckboxField>
                    <Consent>
                      <p>
                        You can withdraw your consent at any time by using the Unsubscribe mechanism provided within the content. For more information on your rights and the processing of your data, please consult our <a href='/en-ww/privacy-cookies-notice/'>Information note on the processing of personal data and cookies</a>.
                      </p>
                    </Consent>
                  </>
                )
              }
              {
                (!['Germany', 'Poland', 'Japan', 'USA'].includes(countryName)) && (
                  <>
                    <Consent>
                      <p>
                        By clicking on “submit”, I confirm I have read the <a href={ `/${countriesData.countries.find((c) => c.label === countryName)?.market}/privacy-cookies-notice` }>Information note on the processing of personal data and cookies</a>.
                      </p>
                    </Consent>
                    <Consent>
                      <p>
                        Your personal data are is processed by Cloudy Bay to ensure the efficient processing of your request (and/or other purpose). For more information on the processing of your data and on your rights, you can visit our <a href={ `/${countriesData.countries.find((c) => c.label === countryName)?.market}/privacy-cookies-notice/#notice-collection` }>Information on the processing of personal data and cookies</a>.
                      </p>
                    </Consent>
                    <CheckboxField
                      ref={ newsletterRef }
                      name='data_consent'
                    >
                      <Consent>
                        <p>
                          Yes! I consent to be the first to receive by email personalized communications and be eligible to receive invitations to exclusive VIP events. In order to better serve my needs, I understand that <a href={ `/${countriesData.countries.find((c) => c.label === countryName)?.market}/privacy-cookies-notice/` }>my data</a> is processed, analyzed and shared with our parent company, <a href='https://www.lvmh.com/houses/' target='_blank' rel='noreferrer'>LVMH</a>, which includes Cloudy Bay plus other prestigious champagne, spirits, fashion, jewelry and hospitality brands.
                          My interaction with communication may be measured by automatic means.
                        </p>
                      </Consent>
                    </CheckboxField>
                    <Consent>
                      <p>
                        You can withdraw your consent at any time by using the Unsubscribe mechanism provided within the content. For more information on your rights and the processing of your data, please consult our <a href={ `/${countriesData.countries.find((c) => c.label === countryName)?.market}/privacy-cookies-notice/#notice-collection` }>Information note on the processing of personal data and cookies</a>.
                      </p>
                    </Consent>
                  </>
                )
              }
            </Checkboxes>
          </FormGroup>

          {!isSend && <Button ctaTracking={ false }>Submit</Button>}
          {isSend && <SuccessMessage>Your message has been sent. We will be in touch shortly.</SuccessMessage>}
        </FormContainer>
      </GridContainer>
    </Container>
  )
}

export default ContactForm
