import * as React from 'react'
import PropTypes from 'prop-types'
import Icon from '@/components/Icon'
import formatText from '@/utils/formatText'

import {
  Container,
  CardHead,
  Title,
  Text,
  CardContent,
  CardContentItemDetails,
  DetailsItem,
  DetailsItemPicto,
  DetailsItemContent,
  DetailsItemContentTop,
  DetailsItemContentLabel,
  DetailsItemContentInfo,
  CardFooter,
  DetailsItemLink,
  Top
} from './style'

const HeroCard = ({ title = '', text = '', items = [], actions = [], display = 'column', block = '' }) => {
  return (
    <Container className={ block }>
      <CardHead>
        {
          (!!title.length || !!text.length) && (
            <Top>
              { !!title.length && <Title>{ title }</Title> }
              { !!text.length && <Text>{ text }</Text> }
            </Top>
          )
        }
      </CardHead>
      <CardContent>
        <CardContentItemDetails className={ display }>
          { items.map((item, i) => (
            <DetailsItem key={ i }>
              <DetailsItemPicto>
                <Icon name={ item.icon } size={ 32 } />
              </DetailsItemPicto>
              <DetailsItemContent>
                { (item?.top && item.top.length > 0) && (
                  <DetailsItemContentTop>{ item.top }</DetailsItemContentTop>
                ) }
                <DetailsItemContentLabel>{ item.label }</DetailsItemContentLabel>
                <DetailsItemContentInfo dangerouslySetInnerHTML={ { __html: formatText(item.content) } } />
                {item.number && <DetailsItemLink href={ `tel:${item.number}` }>{item.number}</DetailsItemLink>}
                {item.number && <DetailsItemLink target='_blank' href={ `http://maps.google.com/?q=${item.lat},${item.lon}` }>View on google maps</DetailsItemLink>}
              </DetailsItemContent>
            </DetailsItem>
          )) }
        </CardContentItemDetails>
      </CardContent>
      <CardFooter className={ actions.length > 1 && 'many' }>
        {
          !!actions.length && (
            actions.map((action) => (
              action
            ))
          )
        }
      </CardFooter>
    </Container>
  )
}

HeroCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.string
  })),
  actions: PropTypes.array,
  block: PropTypes.string,
  display: PropTypes.oneOf(['column', 'grid'])
}

export default HeroCard
